import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';
const WIDGET_API_URL_STAGE = 'https://backend-stage.estate.hart-digital.com';

const createWidget = async () => {
  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get("id");
  const env = searchParams.get("env");

  const widgetApiHandler = new ApiStore(env === 'stage' ? WIDGET_API_URL_STAGE : WIDGET_API_URL);

  const planData = await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation', 'area'],
    locale: 'ru',
  }

  new Widget('#widget', options);
}

createWidget();
